import { Component, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import BadgesMixin from '@/modules/level-two/views/event/modules/badges/mixins/badges.mixin'
import { mapState } from 'vuex'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { BadgeFormModes, BadgeFormMode, BadgeFormOptions } from '@/modules/level-two/models/badges.model'
import GtrBadgePreview from '@/modules/common/components/ui-core/gtr-badge-preview/gtr-badge-preview.vue'

@Component({
  name: 'GtrBadgesBadgeList',
  components: {
    'gtr-badge-preview': GtrBadgePreview
  },
  computed: {
    ...mapState('badges', ['badges', 'participantBadge']),
    ...mapState('option', ['option_group_names'])
  }
})
export default class GtrBadgesBadgeList extends mixins(GtrSuper, BadgesMixin) {
  badges!: Array<Record<string, any>>;

  BadgeFormModes = BadgeFormModes

  previewData = false;

  participantBadge!: any

  option_group_names!: Array<Record<string, any>>

  data () {
    return {
      loading: false,
      previewModal: {
        svg: '',
        svgUnaltered: '',
        dataPreview: '',
        title: '',
        regType: '',
        isOpen: false
      }
    }
  }

  async mounted () {
    try {
      this.$data.loading = true
      this.$store.commit('badges/SET_PARTICIPANT_BADGE', {})
      await this.$store.dispatch('option/getOptionsGroupNames', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async toggleDataPreview (e: MouseEvent): Promise<void> {
    if (!this.$data.previewModal.dataPreview && Object.keys(this.participantBadge).length === 0) {
      try {
        const payload: any = {
          event_uuid: this.$route.params.event_uuid,
          option_group_uuid: this.regTypesOptionGroupUuid,
          option_uuid: this.$data.previewModal.regType
        }
        const rando = await this.$store.dispatch('attendee/fetchRandomAttendee', payload)
        await this.fetchAttendeeBadge(rando)
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      }
    }
    this.previewData = !this.previewData
  }

  async fetchAttendeeBadge (rando: any): Promise<any> {
    try {
      this.$data.loading = true
      return this.$store.dispatch('badges/fetchAttendeeBadgePNG', {
        event_uuid: this.$route.params.event_uuid,
        data: {
          type: '5384',
          participant_uuids: [rando.uuid],
          asPNG: true
        }
      })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  @Watch('participantBadge', { immediate: true })
  onParticipantBadgeChange (value: any) {
    if (value) {
      this.$data.loading = false
    }
  }

  get badgeURL (): string {
    return this.participantBadge?.result_data?.url ?? ''
  }

  get regTypesOptionGroupUuid () {
    const group = this.option_group_names?.filter(group => group.name === 'Registration Types').pop()
    return group ? group.uuid : null
  }

  async openBadgePreviewModal (index: number) {
    this.$data.previewModal.svgUnaltered = this.getSvgPreviewUnaltered(this.badges[index].svg)
    this.$data.previewModal.title = this.badges[index].name
    this.$data.previewModal.regType = this.badges[index].registration_type
    this.$data.previewModal.isOpen = true
  }

  handleClosePreviewModal () {
    this.$data.previewModal.isOpen = false
    setTimeout(() => {
      this.$store.commit('badges/SET_PARTICIPANT_BADGE', {})
      this.previewData = false
      this.$data.previewModal.title = ''
      this.$data.previewModal.regType = ''
    }, 300)
  }

  openBadgeFormModal (mode: BadgeFormMode, index: number, active?: 0 | 1) {
    let title = ''
    if (mode === BadgeFormModes.EDIT) {
      title = 'Edit Badge'
    } else if (mode === BadgeFormModes.DUPLICATE) {
      title = 'Duplicate Badge'
    }
    const badgeFormOptions: BadgeFormOptions = {
      mode: mode,
      title: title,
      badge: this.badges[index]
    }
    if (active === 0 || active === 1) {
      badgeFormOptions.active = active
    }
    this.$emit('openBadgeFormModal', badgeFormOptions)
  }

  async deactivateBadge (index: number) {
    try {
      this.$store.dispatch('common/showLoader', { value: true })
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        badge_uuid: this.badges[index].uuid,
        data: {
          active: 0,
          registration_type: null
        }
      }
      await this.$store.dispatch('badges/updateBadge', payload)
      await this.$store.dispatch('badges/fetchBadges', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$store.dispatch('common/hideLoader')
    }
  }

  openInSvgEditor (index: number) {
    const badge = this.badges[index]
    if (!badge.svg.includes('gtr-svg-editor') && !badge.svg.includes('canvas')) {
      Container.get(Notification).error('This badge was not created with GTR Badge Editor.')
      return
    }
    if (badge.svg.includes('gtr-svg-editor') || badge.svg.includes('canvas')) {
      const badgeSize = badge.badge_properties.size
      this.$emit('openSvgBuilderModal', badgeSize, badge)
    }
  }
}
